<template>
  <Box>

    <Row>
      <Column :width="15">
        <Box :key="redraw">
          <BillingUpdateRow v-if="this.showUpdateBilling" :settingsId="this.selectedSettingsId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></BillingUpdateRow>
          <CardUpdateRow v-if="this.showUpdateCard" :cardId="this.selectedCardId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></CardUpdateRow>
          <WorkingDisplayRow v-if="this.showWorking" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></WorkingDisplayRow>

          <Row 
            v-for="(row, rowIndex) in viewData" 
            :key="rowIndex" >
            
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                 />

          </Row>
        </Box>
      </Column>
    </Row>

  </Box>
  
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import BillingUpdateRow from './billing/BillingUpdateRow.vue';
import CardUpdateRow from './card/CardUpdateRow.vue';
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import DisplayViewModel from './DisplayViewModel.js';
import DisplayViewUtils from './DisplayViewUtils.js';

export default {
  name: "billing-display-view",
  components: {
    Column, Box, Row,
    CellFactory,
    BillingUpdateRow,
    CardUpdateRow,
  },
  props: {
    prop: { type: Boolean, default: false },
  },
  data() {
    return {
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
      
      CellFactory: CellFactory,
      
      viewModel: null,
      viewData: [],
      showMissingRequirements: false,

      showUpdateBilling: false,
      showUpdateCard: false,
      showWorking: false,

      selectedSettingsId: null,
      selectedCardId: null,

      DisplayViewModel: DisplayViewModel,
      DisplayViewUtils: DisplayViewUtils,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    },
    $route (to, from){
      if (to && from) {
        if (to.query["action"] === "update") {
          this.selectedSettingsId = to.query["settingsId"];
          this.showUpdateBilling = true;

        } else if (to.query["action"] === "updateCard") {
          this.selectedCardId = to.query["cardId"];
          this.showUpdateCard = true;

        } else {
          if (this.showUpdateBilling) {
            this.showUpdateBilling = false;

          } else if (this.showUpdateCard) {
            this.showUpdateCard = false;

          } else {
            this.reloadPage();
          }
        }
      }
    },
  },
  mounted: function () {
    var companyId = this.Contents.baseDecode(this.$route.params.operatorId);
    this.viewModel = new this.DisplayViewModel(this, companyId);
    this.viewModel.start();
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.viewModel.withStateContentLoading();
      this.viewModel.clearAndBuildTableData();
      this.viewData = this.viewModel.viewData();
      this.paint();
      this.viewModel.loadItems();
    },

    
    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      var row = this.viewModel.findRowProgress();
      if (row) {
        row.withMessage(msg);
      }
      this.paint();
    },
    
    model: function() {
      return this.viewModel;
    },
    
    withValue: function(valueData) {
      this.model().withValue(valueData);
    },
    
    withButtonPressed: function(buttonData) {
      this.model().withButtonPressed(buttonData);

      if (buttonData["field"] === "DetailsClose") {
        var query = Object.assign({}, this.$route.query);
        query = this.deleteDetails(query);
        this.$router.push({ query });
        if (buttonData["action"] == "reload") {
          this.reloadPageDelayed();
        }
      }

      if (buttonData["field"] === "UpdateButton") {
        const query = Object.assign({}, this.$route.query);
        query["action"] = "update";
        query["settingsId"] = this.Contents.baseEncode(buttonData["id"]);
        this.$router.push({ query });
      }

      if (buttonData["field"] === "UpdateCcButton") {
        const query = Object.assign({}, this.$route.query);
        query["action"] = "updateCard";
        query["cardId"] = this.Contents.baseEncode(buttonData["id"]);
        // this.$router.push({ name: 'operatorPaymentEdit', query });
        this.$router.push({ query });
      }
    },
    
    deleteDetails(query) {
      delete query.action;
      delete query.settingsId;
      delete query.cardId;
      return query;
    },

    reloadPageDelayed: function() {
      this.model()
        .withStateContentLoading()
        .clearAndBuildTableData()
        .done();
      setTimeout(this.reloadPage, 500);
    },

    reloadPage: function() {
      this.viewModel.withStateContentLoading();
      this.viewModel.clearAndBuildTableData();
      this.viewModel.loadItems();
    },

    paint: function() {
      this.redraw++;
    },

    complete: function() {
      setTimeout(this.completeImpl, 200);
    },

    completeImpl: function() {
      const action = this.$route.query["action"]
      const settingsId = this.$route.query["settingsId"];
      if (action == "update" && settingsId) {
        this.selectedSettingsId = settingsId;
        this.showUpdateBilling = true;
      }

      const cardId = this.$route.query["cardId"];
      if (action == "updateCard" && cardId) {
        this.selectedCardId = cardId;
        this.showUpdateCard = true;
      }
    }
  },
}
</script>